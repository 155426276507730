import { graphql } from "gatsby";
import React, {useState} from "react";
import styled from "styled-components";
import { useStringsContext } from "../../contexts/strings-context";
import { SEO } from "../../components/SEO";
import { PageProps } from "../../types/global";
import { navigate } from 'gatsby-link';
import { SiMinutemailer, SiFacebook, SiLinkedin, SiInstagram, SiWhatsapp, SiTwitter, SiGithub, SiGitlab, SiDiscord, SiTelegram, SiGmail } from "react-icons/si";
import { RiUser3Line } from "react-icons/ri";
import { BsChatText } from "react-icons/bs";
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"
import { MdQuestionAnswer} from "react-icons/md";
import MyMap from "~/components/myMap";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            
          }
    }
`;
const GetInTouch = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5rem;
`;
const Message = styled.p`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    a {
        padding: 0.5rem;
        width: 7rem;
        height: 7rem;
        text-decoration: none;
        color: white;
        @media (max-width: 700px) {
            width: 1.5rem;
            height: 1.5rem;
            padding: 1rem;
        }
    }
`;
const MainText = styled.p`
    display: table-row;
`;
const MainTextCenter = styled(MainText)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 1rem;
`;
const Center= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Label = styled.label`
    display: table-cell;
    font-size: 1.2em;
    color: #bfc6bf;
    font-family: "Roboto";
    font-weight: 400;
    margin: 0;
    text-align: left;
    padding: 1rem;
`;
const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    height: 100%;
    overflow: hidden;
`;
const Form = styled.form`
    display: table;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding: 50px;
`;

const Input = styled.input`
    background: transparent;
    width: 100%;
    clear: both;
    color: white;
    display: table-cell;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    background-color: #383838;
    font-family: 'Gloria Hallelujah';
`;
const TextArea = styled.textarea`
    background: transparent;
    width: 100%;
    clear: both;
    color: white;
    display: table-cell;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border-radius: 15px;
    background-color: #383838;
    font-family: 'Gloria Hallelujah';
`;

const Button = styled.button`
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    padding: 0.2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    color: #bfc6bf;
    font-family: "Roboto";
    font-weight: 400;
`;

const ContactUS = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    color: #797979;
    font-family: "Roboto";
    font-weight: 400;
    padding: 0 0 1rem 0;
`;
const SiCoffeescriptWhite = styled(MdQuestionAnswer)`
    color: #fffddd;
    background: transparent;
    width: 3rem;
    height: 3rem;
    padding: 0 2rem;
`;
const SiFacebookSize = styled(SiFacebook)`
    @media (max-width: 700px) {
        width: 1.5rem;
        height: 1.5rem;
    }
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    :hover {
        color: #0077ff;
        
    }
`;
const SiInstagraSize = styled(SiInstagram)`
    @media (max-width: 700px) {
        width: 1.5rem;
        height: 1.5rem;
    }
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    :hover {
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        border-radius: 20px;
        
    }
`;
const SiWhatsappSize = styled(SiWhatsapp)`
    @media (max-width: 700px) {
        width: 1.5rem;
        height: 1.5rem;
    }
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    :hover {
        color: #0afd17;
        
    }
`;

const SiDiscordSize = styled(SiDiscord)`
    @media (max-width: 700px) {
        width: 1.5rem;
        height: 1.5rem;
    }
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    :hover {
        color: #4e3fdb;
        
    }
`;
const SiTelegramSize = styled(SiTelegram)`
    @media (max-width: 700px) {
        width: 1.5rem;
        height: 1.5rem;
    }
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    :hover {
        color: #0099ffc8;
        
    }
`;
const SiLinkedinSize = styled(SiLinkedin)`
@media (max-width: 700px) {
        width: 1.5rem;
        height: 1.5rem;
    }
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    :hover {
        color: #044fb1;
        
    }
`;

const SiGmailSize = styled(SiGmail)`
    @media (max-width: 700px) {
        width: 1.5rem;
        height: 1.5rem;
    }
    width: 4rem;
    height: 4rem;
    transition: 1s all;
    :hover {
        color: red;
        
    }
`;

function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  
const EnquirePage: React.FunctionComponent<PageProps> = (props)=> {
    const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  } 
    const strings = useStringsContext();
    const [submitted, setSubmitted] = useState(false)
    const submit = (token) => {}
    return (
    <>
    
    <SEO
                lang={props.pageContext.language}
                title="Varqa School - Enquire"
                description="Varqa School &amp; Junior College is one of the most prestigious schools in Ahmednagar District, having been around for more than 40 years. The school is in an area that is rich in greenery and is surrounded by an extremely healthy and open environment. Our school is also partnered with Cambridge University to provide our students with more rich content, and the school is committed to maintaining highly-trained and innovative faculty."
                keywords={["varqa", "school", "varqa school", "varka", "vaka", "varkha", "varqa school", "college", "ahmenagar", "ahmednagar", "maharashtra", "best school", "schools near me", "schol", "schoole", "schools", "varqa", "best", "top schools", "cbse", "cbse school", "state board", "state", "english medium", "school medium", "english school", "english", "ramin", "ruhiya", "saba", "sir", "madam", "ahmednagr best school", "nagar best school", "nagar", "school in nagar", "nagar school"]}
                url={props.location.pathname}
            />
    <Container>
        <ContactUS>Enquire<SiCoffeescriptWhite/></ContactUS>
        <Form 
            name="enquire"
            method="post"
            action="/enquire/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="enquire" />
            <p hidden>
                <label>Get in touch with the school <input name="bot-field" onChange={handleChange} />
                </label>
            </p>
            <MainText>
                <Label>Full Name: <RiUser3Line/><Input type="text" name="name" onChange={handleChange}/></Label>   
            </MainText>
            <MainText>
                <Label>Email ID: <SiMinutemailer/><Input type="email" name="email" onChange={handleChange}/></Label>
            </MainText>
            <MainText>
                <Label>Text me: <BsChatText/><TextArea name="message" onChange={handleChange}></TextArea></Label>
            </MainText>
            <MainTextCenter>
                <Button data-action='submit' onClick={() => setSubmitted(true)} type="submit">Send</Button>
            </MainTextCenter>
            <Center>
                <ReCaptcha
                action="submit"
                siteKey="6LfufqceAAAAADVZVGy2gWR4S1DVMPB5TJbKuLkg"
                onVerify={(token) => submit(token)}
                submitted={submitted}
                size="invisible"
            />
            </Center>
        </Form>
    </Container>
    <Message>
                <a href="https://www.facebook.com/varqaschoolandjrcollege"><SiFacebookSize/></a>
                <a href="https://www.instagram.com/varqaschool"><SiInstagraSize/></a>
                <a href="https://wa.me/+919822408237"><SiWhatsappSize/></a>
                <a href="https://www.linkedin.com/company/varqa-school/"><SiLinkedinSize/></a>
                <a href="https://discord.gg/SwjxHw5k"><SiDiscordSize/></a>
                <a href="https://t.me/+058fNwP0pFxkYzU0"><SiTelegramSize/></a>
                <a href="mailto: varqaschool@gmail.com"><SiGmailSize/></a>
            </Message>
    </>
    )
}

export default EnquirePage
